<template>
  <div class="lang" :class="{ '-open': showLang }">
    <button
      type="button"
      @click="onClick"
      :aria-expanded="showLang ? 'true' : 'false'"
      aria-controls="menu-lang"
      id="btn-lang"
      :tabindex="isMobile && !menuOpen ? -1 : 0"
    >
      <i class="icon-glob"></i>
      {{ $t(lang) }} - {{ currency }}
    </button>
    <transition name="fade">
      <div
        class="menu_lang"
        v-show="showLang"
        role="region"
        aria-labelledby="btn-lang"
        id="menu-lang"
        v-bsl="showLang && menuOpen"
      >
        <strong class="-upper">{{ $t('Choose your Country/Region') }}</strong>
        <ul class="world">
          <li>
            <button
              type="button"
              aria-controls="lang-america"
              :aria-expanded="world === 1 ? 'true' : 'false'"
              :class="{ '-active': world === 1 }"
              @click="world = world === 1 ? 0 : 1"
            >
              {{ $t('America') }}
            </button>
            <strong>{{ $t('America') }}</strong>
            <ul id="lang-america">
              <li
                v-for="country in ameriques"
                :key="country.name + country.i18n.defaultLanguage"
              >
                <a
                  v-if="country.include_in_menu && country.accessibility"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  ><span aria-hidden="true">{{ country.name }}</span>
                  <span class="a11y-sr-only">{{
                    $t(country.accessibility)
                  }}</span></a
                >
                <a
                  v-else-if="country.include_in_menu"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  :aria-label="country.name + ' - ' + $t('Ecommerce store')"
                  >{{ country.name }}</a
                >
                <i
                  v-if="country.include_in_menu && country.is_e_com"
                  class="icon-cart"
                  :aria-hidden="true"
                ></i>
              </li>
            </ul>
          </li>
          <li>
            <button
              type="button"
              aria-controls="lang-europe"
              :aria-expanded="world === 3 ? 'true' : 'false'"
              :class="{ '-active': world === 3 }"
              @click="world = world === 3 ? 0 : 3"
            >
              {{ $t('Europe') }}
            </button>
            <strong>{{ $t('Europe') }}</strong>
            <ul id="lang-europe">
              <li v-for="country in europe" :key="country.name">
                <a
                  v-if="country.include_in_menu && country.accessibility"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  ><span aria-hidden="true">{{ country.name }}</span>
                  <span class="a11y-sr-only">{{
                    $t(country.accessibility)
                  }}</span></a
                >
                <a
                  v-else-if="country.include_in_menu"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  :aria-label="country.name + ' - ' + $t('Ecommerce store')"
                  >{{ country.name }}</a
                >
                <i
                  v-if="country.include_in_menu && country.is_e_com"
                  class="icon-cart"
                  :aria-hidden="true"
                ></i>
              </li>
            </ul>
          </li>
          <li>
            <button
              type="button"
              aria-controls="lang-asia"
              :aria-expanded="world === 4 ? 'true' : 'false'"
              :class="{ '-active': world === 4 }"
              @click="world = world === 4 ? 0 : 4"
            >
              {{ $t('Asia Pacific') }}
            </button>
            <strong>{{ $t('Asia Pacific') }}</strong>
            <ul id="lang-asia">
              <li v-for="country in apac" :key="country.name">
                <a
                  v-if="country.include_in_menu && country.accessibility"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  ><span aria-hidden="true">{{ country.name }}</span>
                  <span class="a11y-sr-only">{{
                    $t(country.accessibility)
                  }}</span></a
                >
                <a
                  v-else-if="country.include_in_menu"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  :aria-label="country.name + ' - ' + $t('Ecommerce store')"
                  >{{ country.name }}</a
                >
                <i
                  v-if="country.include_in_menu && country.is_e_com"
                  class="icon-cart"
                  :aria-hidden="true"
                ></i>
              </li>
            </ul>
          </li>
          <li>
            <button
              type="button"
              aria-controls="lang-other"
              :aria-expanded="world === 5 ? 'true' : 'false'"
              :class="{ '-active': world === 5 }"
              @click="world = world === 5 ? 0 : 5"
            >
              {{ $t('Middle East') }}
            </button>
            <strong>{{ $t('Middle East') }}</strong>
            <ul id="lang-other">
              <li v-for="country in middleEast" :key="country.name">
                <a
                  v-if="country.include_in_menu && country.accessibility"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  ><span aria-hidden="true">{{ country.name }}</span>
                  <span class="a11y-sr-only">{{
                    $t(country.accessibility)
                  }}</span></a
                >
                <a
                  v-else-if="country.include_in_menu"
                  :class="{ active: localizedUrl === country.url }"
                  :href="clientUrl + country.url + currentRoute"
                  :aria-label="country.name + ' - ' + $t('Ecommerce store')"
                  >{{ country.name }}</a
                >
                <i
                  v-if="country.include_in_menu && country.is_e_com"
                  class="icon-cart"
                  :aria-hidden="true"
                ></i>
              </li>
            </ul>
          </li>
          <li>
            <div
              class="int"
              v-for="country in international"
              :key="country.name"
            >
              <a
                v-if="country.include_in_menu && country.accessibility"
                :class="{ active: localizedUrl === country.url }"
                :href="clientUrl + country.url + currentRoute"
                ><span aria-hidden="true">{{ country.name }}</span>
                <span class="a11y-sr-only">{{
                  $t(country.accessibility)
                }}</span></a
              >
              <a
                v-else-if="country.include_in_menu"
                :class="{ active: localizedUrl === country.url }"
                :href="clientUrl + country.url + currentRoute"
                :aria-label="country.name + ' - ' + $t('Ecommerce store')"
                >{{ country.name }}</a
              >
              <i
                v-if="country.include_in_menu && country.is_e_com"
                class="icon-cart"
                :aria-hidden="true"
              ></i>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'

export default {
  props: ['currency', 'lang', 'menuOpen'],
  name: 'Lang',
  mixins: [ReadyHook],
  data() {
    return {
      showLang: false,
      world: 3,
      links: [],
      focused: 0
    }
  },
  computed: {
    // views() {
    //   return config.storeViews.mapStoreUrlsFor
    //     .map(storeCode => config.storeViews[storeCode])
    //     .map(({ i18n, url }) => {
    //       const { fullCountryName, fullLanguageName, region } = i18n
    //       return {
    //         country: fullCountryName,
    //         lang: fullLanguageName,
    //         url,
    //         region
    //       }
    //     })
    //     .sort((a, b) => (a.country > b.country ? (a.lang > b.lang ? 1 : 0) : -1))
    // },
    europe() {
      return Object.values(config.storeViews)
        .filter(country => country.include_in_menu)
        .filter(
          country =>
            country.i18n &&
            country.i18n.region &&
            country.i18n.region === 'europe'
        )
        .sort(function(a, b) {
          return a.name > b.name ? 1 : -1
        })
    },
    ameriques() {
      return Object.values(config.storeViews)
        .filter(country => country.include_in_menu)
        .filter(
          country =>
            country.i18n &&
            country.i18n.region &&
            country.i18n.region === 'america'
        )
        .sort(function(a, b) {
          return a.name > b.name ? 1 : -1
        })
    },
    international() {
      return Object.values(config.storeViews)
        .filter(country => country.include_in_menu)
        .filter(
          country =>
            country.i18n &&
            country.i18n.region &&
            country.i18n.region === 'international'
        )
        .sort(function(a, b) {
          return a.name > b.name ? 1 : -1
        })
    },
    middleEast() {
      return Object.values(config.storeViews)
        .filter(country => country.include_in_menu)
        .filter(
          country =>
            country.i18n &&
            country.i18n.region &&
            country.i18n.region === 'middle_east'
        )
        .sort(function(a, b) {
          return a.name > b.name ? 1 : -1
        })
    },
    apac() {
      return Object.values(config.storeViews)
        .filter(country => country.include_in_menu)
        .filter(
          country =>
            country.i18n &&
            country.i18n.region &&
            (country.i18n.region === 'asia' ||
              country.i18n.region === 'pacific')
        )
        .sort(function(a, b) {
          return a.name > b.name ? 1 : -1
        })
    },
    currentRoute() {
      let { url } = currentStoreView()
      url = url || ''
      return this.$route.path.replace(url, '')
    },
    localizedUrl() {
      return currentStoreView().url || ''
    },
    clientUrl() {
      return config.client.url
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    }
  },
  watch: {
    menuOpen() {
      this.showLang = false
    },
    showLang() {
      this.focused = 0
      this.$nextTick(() => {
        if (this.showLang) {
          this.links = this.$el.querySelectorAll('a')
          this.links[this.focused].focus()
        }
      })

      if (this.showLang) {
         this.$store.commit('zenith-ui/setLang', {
          open: true
        })
      } else {
        this.$store.commit('zenith-ui/setLang', {
          open: false
        })
      }
     
    }
  },
  methods: {
    keydown(e) {
      if (e.keyCode === 38 && this.showLang) {
        e.preventDefault()
        this.focused =
          this.focused - 1 < 0 ? this.links.length - 1 : this.focused - 1
        this.links[this.focused].focus()
      }
      if (e.keyCode === 40 && this.showLang) {
        e.preventDefault()
        this.focused =
          this.focused + 1 >= this.links.length ? 0 : this.focused + 1
        this.links[this.focused].focus()
      }
    },
    keyup(e) {
      if (e.keyCode === 27 && this.showLang) {
        this.showLang = false
      }
    },
    trapFocus(e) {
      if (
        this.showLang &&
        !(this.$el === e.target || this.$el.contains(e.target)) &&
        (!e.target.classList || !e.target.classList.contains('lang'))
      ) {
        this.showLang = false
      }
    },
    onScroll() {
      if (window.innerWidth > 767) {
        this.showLang = false
      }
    },
    onClick() {
      if (!this.showLang) {
        // this.$store.commit('zenith-ui/setMenu', {
        //   open: true,
        //   section: 'lang'
        // })
      }
      this.showLang = !this.showLang
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keydown)
    document.addEventListener('keyup', this.keyup)
    document.addEventListener('focus', this.trapFocus, true)
    document.addEventListener('click', this.trapFocus, true)
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    document.removeEventListener('keydown', this.keydown)
    document.removeEventListener('keyup', this.keyup)
    document.removeEventListener('focus', this.trapFocus, true)
    document.removeEventListener('click', this.trapFocus, true)
    window.addEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.lang {
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100vw;
    height: calc(100vh - 12rem);
    top: 12rem;
    left: 50%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-50%);

    @include bp(max md) {
      display: none;
    }
  }

  &.-open:before {
    opacity: 1;
  }

  & > button {
    position: absolute;
    top: 3rem;
    display: flex;
    border: none;
    background: none;
    padding: 1rem 0 1rem 2.4rem;
    font-size: em(9);
    outline: none;
    align-items: center;
    text-transform: uppercase;
    color: var(--zenith-header-text-link);
    transition: none;

    @include bp(max md) {
      top: auto;
      left: 0;
      bottom: 0;
      width: calc(100% + 3rem);
      position: relative;
      z-index: 10;
      margin: 0 -1.5rem 0;
      display: block;
      font-size: em(14);
      text-align: left;
      padding: 1.6rem 1.5rem 1.4rem;
      border-top: 1px solid var(--zenith-header-border);
      border-bottom: 1px solid var(--zenith-header-border);

      &:focus {
        outline: 1px dashed #383838;
        outline-offset: 4px;
      }
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 0.8rem;
      width: 0;
      height: 0.1rem;
      left: 50%;
      opacity: 0;
      background: var(--zenith-header-text-link);
      transform: translateX(calc(-50% + 1.2rem));
      transition: all 0.2s ease-in-out;

      @include bp(max md) {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:after {
        opacity: 1;
        width: calc(100% - 2.4rem);
      }
    }

    i {
      font-size: em(20, 9);
      vertical-align: middle;
      margin-right: 0.5rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      @include bp(max md) {
        display: none;
      }
    }
  }

  .menu_lang {
    position: absolute;
    z-index: 100;
    top: 12rem;
    left: 0;
    right: 0;
    padding: 6rem 4rem 4rem;
    border-top: 1px solid #dfdfdf;

    @include bp(max md) {
      position: fixed;
      top: 0;
      height: 100vh;
      padding: 3rem 2rem 12rem;
      overflow-y: auto;
      background: var(--zenith-header-background);
    }

    &:before {
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      left: 50%;
      top: -1px;
      background: var(--zenith-header-background);
      width: 100vw;
      height: 100%;
      border-bottom: 1px solid var(--zenith-header-border);
      transform: translateX(-50%);
      @include bp(max md) {
        display: none;
      }
    }

    & > strong {
      display: block;
      font-size: em(22);
      letter-spacing: em(2, 22);
      color: var(--zenith-header-title);

      @include bp(max md) {
        text-align: center;
        font-size: em(18);
        letter-spacing: em(0.4, 18);
        margin-bottom: 5rem;
      }
    }

    ul {
      position: relative;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      strong,
      button {
        display: block;
        font-size: em(16);
        letter-spacing: em(1.5, 16);
        margin-top: 3rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
        background: none;
        border: none;
        width: 100%;
        padding: 0;
        text-align: left;
        color: var(--zenith-header-title);

        @include bp(max md) {
          position: relative;
          font-size: em(14);
          font-weight: normal;
          margin-top: 0;
          margin-bottom: 1rem;
          letter-spacing: em(0.4, 14);

          &:after {
            font-family: 'zenith', sans-serif !important;
            speak: none;
            line-height: 1;
            font-size: em(20);
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: block;
            position: absolute;
            right: 0;
            top: 50%;

            content: '\e913';
            transform: translateY(-50%) rotate(0);
            transition: all 0.2s ease-in-out;
          }

          &.-active {
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }
            & ~ ul {
              opacity: 1;
              max-height: 140rem;
              visibility: visible;
            }
          }
        }
      }

      strong {
        @include bp(max md) {
          display: none;
        }
      }
      button {
        display: none;
        @include bp(max md) {
          display: block;
        }
      }

      a {
        display: inline-block;
        color: var(--zenith-component-text-dark);
        position: relative;
        border: none;
        background: none;
        text-transform: uppercase;
        padding: 1rem 0.6rem 0.6rem;
        margin: 0 -0.6rem;
        font-size: em(12);
        text-align: left;
        outline-offset: -1px;
        letter-spacing: em(0.2, 12);

        @include bp(max md) {
          padding: 1.8rem;
          margin: 0 -1.8rem;
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          bottom: 0.4rem;
          width: 0;
          height: 0.1rem;
          left: 50%;
          opacity: 0;
          background: var(--zenith-theme-primary);
          transform: translateX(-50%);
          transition: all 0.2s ease-in-out;

          @include bp(max md) {
            display: none;
          }
        }

        &.active {
          font-weight: bold;
          color: var(--zenith-theme-primary);

          &:after {
            width: calc(100% - 1.2rem);
            opacity: 1;
          }
        }

        &:hover,
        &:active {
          color: var(--zenith-theme-primary);
          &:after {
            width: calc(100% - 1.2rem);
            opacity: 1;
          }
        }
      }
    }

    .world {
      display: flex;
      flex-wrap: wrap;

      @include bp(max md) {
        display: block;
        height: auto;
      }

      ul {
        @include bp(max md) {
          overflow: hidden;
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          margin-bottom: 3rem;
          transition: all 0.2s ease-in-out;
        }
        li {
          position: relative;
          margin-left: 2.4rem;

          i {
            color: var(--zenith-theme-primary);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-2.4rem, -48%);
            font-size: em(16);
          }

          @include bp(max md) {
            width: 100%;
            margin-left: 0;
            border-bottom: 1px solid var(--zenith-header-border);

            i {
              left: auto;
              right: 0;
              font-size: em(20);
              transform: translate(0, -60%);
            }
          }
        }
      }

      & > li {
        &:nth-child(2) {
          width: 33.33333334%;

          ul {
            column-count: 3;
          }
          @include bp(max lg) {
            width: 50%;
            order: 0;
          }
          @include bp(max md) {
            width: 100%;
            order: 0;

            ul {
              margin-bottom: 3rem;
              column-count: auto;
            }
          }
        }

        &:first-child {
          width: 16.66666667%;
          @include bp(max lg) {
            width: 50%;
            order: 2;
          }
          @include bp(max md) {
            width: 100%;
            order: 0;
          }
        }

        &:nth-child(3) {
          width: 33.33333334%;
          @include bp(max lg) {
            width: 50%;
            order: 1;
          }

          ul {
            column-count: 2;
          }

          @include bp(max md) {
            order: 0;
            width: 100%;
            padding: 0;
            margin: 0;
            border: none;

            ul {
              column-count: auto;
            }
          }
        }

        &:nth-child(4) {
          width: 16.66666667%;
          @include bp(max lg) {
            width: 50%;
            order: 3;
          }
          @include bp(max md) {
            order: 0;
            width: 100%;
          }
        }

        &:last-child {
          border-top: 1px solid #dfdfdf;
          margin-top: 3rem;
          padding-top: 3rem;
          width: 100%;

          ul {
            column-count: 2;
          }

          @include bp(max lg) {
            order: 4;
          }

          @include bp(max md) {
            order: 0;

            ul {
              column-count: auto;
            }
          }
        }
      }

      .int {
        @include bp(max md) {
          margin-bottom: 8rem;
        }
        a {
          letter-spacing: em(2, 12);
        }
      }
    }
  }
}
</style>
